import React from 'react';
import IndicatorComponent from './indicatorComponent';
import { formatNumber } from '../consts/formatNumberConsts';

interface IndicatorsProps {
  leadsCount: number;
  reg: number;
  ftd: number;
  subCount: number;
}

const IndicatorsComponent: React.FC<IndicatorsProps> = ({ leadsCount, reg, ftd, subCount }) => {

  const fdRate = ftd * 100 / leadsCount;
  const ctr = reg * 100 / leadsCount;
  const reg2Fd = ftd * 100 / reg;

  return (
    <div className="wrapper">
      <IndicatorComponent 
        name={'Leads'} 
        count={leadsCount.toString()} 
        procent={`${formatNumber(fdRate)}`} 
        procentTxt={'FD Rate'}
      />
      <IndicatorComponent 
        name={'Registered'} 
        count={reg.toString()} 
        procent={`${formatNumber(ctr)}`} 
        procentTxt={'CTR '}
      />
      <IndicatorComponent 
        name={'FTD'} 
        count={ftd.toString()} 
        procent={`${formatNumber(reg2Fd)}`} 
        procentTxt={'Reg2Fd'}
      />
      <IndicatorComponent 
        name={'Subs'} 
        count={subCount.toString()} 
        procent={``} 
        procentTxt={'!'}
      />
    </div>
  );
};

export default IndicatorsComponent;

import { formatNumber } from "../consts/formatNumberConsts";
import CustomDepComponent from "./customDepComponent";

interface IndicatorsProps {
    ftd: number;
    deposits: number;
    leadsCount: number;
    constProcent: number;
    subCount: number;
}

const DepStats: React.FC<IndicatorsProps> = ({ ftd, deposits, leadsCount, constProcent, subCount }) => {
    let epl =  deposits / leadsCount;
    let arpu = deposits / ftd;
    let arpuSub = deposits / subCount;
    
    if(Number.isNaN(epl) || Number.isNaN(arpu) || epl == Infinity || arpu == Infinity) {
        epl = 0
        arpu = 0
    }
    
    const nearEpl = Number(formatNumber(epl)) - Number(formatNumber(epl/100 * constProcent))
    const nearArpu = Number(formatNumber(arpu)) - Number(formatNumber(arpu/100 * constProcent))
    const nearArpuSub = Number(formatNumber(arpuSub)) - Number(formatNumber(arpuSub/100 * constProcent))
    const nearDep = Number(formatNumber(deposits)) - Number(formatNumber(deposits/100 * constProcent))

    return (
        <div className="wrapper">
            <CustomDepComponent 
                name={'EPL'}
                dep={`${formatNumber(epl)}`} 
                near={`(~ ${formatNumber(nearEpl)})`}
            />
            <CustomDepComponent 
                name={'Deposits'} 
                dep={`${formatNumber(deposits)}`}
                near={`(~ ${formatNumber(nearDep)})`}
            />
            <CustomDepComponent 
                name={'ARPU (Hit)'} 
                dep={`${formatNumber(arpu)}`}
                near={`(~ ${formatNumber(nearArpu)})`} 
            /> 
            <CustomDepComponent 
                name={'ARPU (Sub)'} 
                dep={`${formatNumber(arpuSub)}`}
                near={`(~ ${formatNumber(nearArpuSub)})`} 
            /> 
        </div>
    );
}

export default DepStats;
    
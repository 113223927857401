import React from "react";
import InputsComponent from "../components/inputsComponent";
import IndicatorsComponent from "../components/indicatorsComponent";
import { Config, DataItem, PocketId } from '../types';
import Spinner from "../components/spinnerComponent";
import ErrorBlock from "../components/errorComponent";
import Pagination from "../components/paginationComponent";
import DepStats from "../components/depStatsComponent";
import FilteredStats from "../components/filteredStatsComponent";

interface DashboardProps {
  data: DataItem[],
  isLoading: boolean,
  changeReqParams: (params: string, startDate: string, endDate: string) => void,
  isError: string,
  hideErrorByClick: () => void,
  fetchPocketId: (config: Config) => Promise<void>,
  config: Config,
  pocketIdData: PocketId[],
  constProcent: number;
  subCount: number;
  showHideError: (err: string) => void;
}

const Dashboard: React.FC<DashboardProps> = ({ 
    data, 
    isLoading, 
    changeReqParams, 
    isError,
    hideErrorByClick, 
    fetchPocketId, 
    config,
    pocketIdData,
    constProcent,
    showHideError,
    subCount
  }) => {
  
  let summary = { activated: 0, registered: 0 };
  let totalSumDep = 0;
  let higherTotalDep = 0;
  const highrollersPocketId: string[] = [];

  data.forEach(el => {
    summary.activated += Number(el.activated);
    summary.registered += Number(el.registered);
  });

  pocketIdData.forEach( el => {
    totalSumDep += el.totalDep
    if(el.totalDep >= 1000) {
      higherTotalDep++
      highrollersPocketId.push(el.pocket_id)
    }
  })

  const fetchHighrollersPocketId = async () => {
    if (highrollersPocketId.length === 0) return showHideError('No highrollers pocket id in this campaign')
    const blob = new Blob([highrollersPocketId.join('\n')], { type: 'text/plain' });
    const urla = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = urla;
    a.download = 'pocketId.txt';
    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(urla); 
  }

  return (
    <>
      <InputsComponent 
        changeReqParamsL={changeReqParams}
        config={config}
        showHideError={showHideError}
      />

      {isLoading ? 
        <Spinner />
      : 
        <>
          <IndicatorsComponent 
            leadsCount={data.length} 
            reg={summary.registered} 
            ftd={summary.activated} 
            subCount={subCount}
          />
      
          {isError !== '' && <ErrorBlock hideErrorByClick={hideErrorByClick} errorTxt={isError} />}

          <DepStats 
            leadsCount={data.length} 
            deposits={totalSumDep} 
            ftd={summary.activated}
            constProcent={constProcent}
            subCount={subCount}
          />

          <FilteredStats 
            totalDep={higherTotalDep}
            ftd={summary.activated} 
          />

          <div className="wrapper">
            <Pagination fetchPocketId={fetchPocketId} config={config} name="Fetch pocket ID"/>
            <Pagination fetchPocketId={fetchHighrollersPocketId} config={config} name="Fetch highrollers pocket ID"/>
          </div>
        </>
      }
    </>
  );
};

export default Dashboard;

import React from 'react';

interface IndicatorProps {
  name: string;
  dep: string;
  near: string;
}

const CustomDepComponent: React.FC<IndicatorProps> = ({ name, dep, near }) => {

  return (
    <div className="indicators">
      <div className="index">
        {name}
      </div>
      <div className="procent">
        {dep} <span className='near-span'>{near}</span>$
      </div>
    </div>
  );
};

export default CustomDepComponent;

import { useEffect, useState } from 'react';
import Dashboard from './pages/dashboard';
import { DataItem, DashboardResponse, Config, PocketIdResponse, PocketId, ParamsSearchI, SubsResponse } from './types';

function App() {
  const [campaigns, setCampaigns] = useState<string>('');
  const [allCampaigns, setAllCampaigns] = useState<string>('');
  const [data, setData] = useState<DataItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<string>('');
  const [config, setConfig] = useState<Config | null>(null);
  const [pocketIdData, setPocketIdData] = useState<PocketId[]>([]);
  const [paramsSearch, setParamsSearch] = useState<ParamsSearchI>({startDate: '', endDate: ''})
  const [constProcent, setConstProcent] = useState<number>(0)
  const [subCount, setSubCount] = useState<number>(0)

  const showHideError = (err: string) => {
    setIsError(err);
    setTimeout(() => {
      setIsError('');
    }, 3500);
  };

  const hideErrorByClick = () => {
    setIsError('');
  };

  const fetchConfig = async () => {
    try {
      const response = await fetch('/config.json');
      const configData: Config = await response.json();
      setConfig(configData);
    } catch (error) {
      showHideError('Failed to load configuration');
    }
  };

  const fetchCampaigns = async (config: Config) => {
    setIsLoading(true);
    try {
      const url = `${config.baseUrl}${config.endpoints.campaigns}`;
      const response = await fetch(url);
      if (!response.ok) {
        showHideError('Error in getting full data');
        setIsLoading(false);
        return;
      }

      const result = await response.json();
      setAllCampaigns(result)
      setCampaigns(result);
    } catch (error) {
      showHideError(`Error fetching campaigns: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const checkHealth = async (config: Config) => {
    try {
      const url = `${config.baseUrl}${config.endpoints.healthCheck}`;
      const response = await fetch(url);

      if (!response.ok) {
        console.log('Error in check health');
        return;
      }

      const result = await response.json();
      console.log('Health Check:', result);
    } catch (error) {
      console.log(`Error during health check: ${error}`);
    }
  };

  const fetchData = async (config: Config) => {
    setIsLoading(true);
  
    const { startDate, endDate } = paramsSearch;
    const campaignSplit = campaigns.split(/[\s,]+/).join(',');
    const queryParams = new URLSearchParams({
      from: startDate || '',
      to: endDate || ''
    }).toString();
  
    const createUrl = (endpoint: string) => `${config.baseUrl}${endpoint}/${campaignSplit}?${queryParams}`;
  
    try {
      const urls = [
        createUrl(config.endpoints.projects),
        createUrl(config.endpoints.getSubs)
      ];
  
      const [response, responseSubs] = await Promise.all(urls.map(url => fetch(url)));
  
      if (!response.ok || !responseSubs.ok) {
        showHideError('Error in getting campaign data');
        return;
      }
  
      const [result, resultSubs] = await Promise.all([
        response.json(),
        responseSubs.json()
      ]);
  
      console.log("resultSubs:", resultSubs);
      setData(result.allRefs);
      setSubCount(resultSubs);
    } catch (error) {
      showHideError(`Error fetching data: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };
  

  const fetchPocketId = async (config: Config) => { 
    const campaignSplit = campaigns.split(/[\s,]+/).join(',');
  
    setIsLoading(true);
    try {
      let url = `${config.baseUrl}${config.endpoints.getPocketIdByCompaign.replace(':campaign', campaignSplit)}`;
      const { startDate, endDate } = paramsSearch;
      const queryParams = {from: '', to: ''};

      if(startDate) {
        queryParams.from = startDate;
      }

      if(endDate) {
        queryParams.to = endDate;
      }
            
      const queryStr = new URLSearchParams(queryParams).toString()
      url += `?${queryStr}`

      const response = await fetch(url);
  
      if (!response.ok) {
        showHideError('Error in getting pockets id');
        return; 
      }
  
      const responseData: string[] = await response.json();
      if (responseData.length === 0) return showHideError('No pocket id in this campaign')
      const blob = new Blob([responseData.join('\n')], { type: 'text/plain' });
      const urla = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urla;
      a.download = 'pocketId.txt';
      document.body.appendChild(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(urla); 
  
    } catch (error) {
      showHideError(`Error fetching campaigns: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };
  
  const getPocketIdData = async (config: Config) => {
    let dataTxtString: string = ``;

    const url = `${config.urlApiPostbackServer}${config.endpoints.bulkFetch}`;

    data.forEach( item => {
      if(item.pocket_id !== undefined && item.activated == '1') dataTxtString+= `${item.pocket_id} \n`
    })

    if(data.length > 0 && dataTxtString !== ``) {
      try {
        setIsLoading(true);
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'text/plain',
          },
          body: dataTxtString,
        });
      
        const result: PocketIdResponse = await response.json();
      
        setPocketIdData(result.response);
      } catch (error) {
        showHideError('Error in getPocketIdData request:');
      } finally {
        setIsLoading(false);
      }
    } else {
      setPocketIdData([{pocket_id: '', totalDep: 0}]);
    }
  };
  
  const removeAndSearchItems = (camp: string) => {
    let allCamp: string[] = allCampaigns.split(/[\s,]+/)
    const searchResults = [];

    const itemsToRemove = camp.split(/[\s,]+/).filter(item => item.startsWith('!')).map(item => item.replace('!', ''));
    const itemsToSearch = camp.split(/[\s,]+/).filter(item => item.startsWith('~')).map(item => item.replace('~', ''));

    for (const searchText of itemsToSearch) {
      for (const item of allCamp) {
        if (item.includes(searchText)) {
          searchResults.push(item);
        }
      }
    }

    if (camp.includes('~') && searchResults.length === 0) return "123"

    if(itemsToSearch.length !== 0) return searchResults.join()
    if(itemsToRemove.length === 0) return camp

    const filteredArray = allCamp.filter(item => !itemsToRemove.includes(item));

    const resToString = filteredArray.join();

    return resToString;
  }

  useEffect(() => {
    const initializeApp = async () => {
      await fetchConfig();
    };
    initializeApp();
  }, []);
  
  useEffect(() => {
    if (config) {
      fetchCampaigns(config);
      checkHealth(config);
      setConstProcent(config.constProcent)
    }
  }, [config]);

  useEffect(() => {
    if (config) {
      getPocketIdData(config);
    }
  }, [data]);

  useEffect(() => {
    if (config && campaigns.trim() !== '') {
      fetchData(config);
    }
  }, [campaigns, config, paramsSearch]);

  const changeReqParams = (string: string, startDate: string, endDate: string) => {
    const res = removeAndSearchItems(string)
    
    setCampaigns(res)
    setParamsSearch({startDate, endDate})
  };

  return (
    <div className="container">
      <div className='title'>{config?.dashName}</div>
      <Dashboard 
        data={data}
        isLoading={isLoading}
        changeReqParams={changeReqParams}
        isError={isError}
        hideErrorByClick={hideErrorByClick}
        fetchPocketId={fetchPocketId}
        config={config as Config}
        pocketIdData={pocketIdData}
        constProcent={constProcent}
        showHideError={showHideError}
        subCount={subCount}
      />
    </div>
  );
}

export default App;
